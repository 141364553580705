import React, { useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import "./style.css";
import exportFromJSON from "export-from-json";
import Calendar from "react-calendar";
import moment from "moment";
import { Table } from "react-bootstrap";
import Search from "../../../components/Search";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import { BASE_URL } from '../../../utils/constants.js';
import { func } from "prop-types";
import apiEndPoints from "../../../utils/apiEndPoints.js";
import { apiCall, setDefaultHeader } from "../../../utils/httpClient";

export default function ReportDetailsComponent(props) {

     const { list } = props;
     const [toggleFrom, settoggleFrom] = useState(false);
     const [toggleto, settoggleto] = useState(false);

     // const [rejectStatus, setRejectStatus] = useState(0);
     // console.log("props", props);

     let auditRecord = {};
     if (list) {
          auditRecord = list?.data?.auditRecords[0];

     }
     else {

     }
     const [showRejectBox, setShowRejectBox] = useState(true)
     const [rejectComment, setRejectComment] = useState(auditRecord.admin_cancel_reason);
     //let showRejectBox = true;


     const gridCols = [[], []];
     

     function GetImageTag(imageName) {
          if (imageName != "") {
               return <img src={BASE_URL + 'upload/question/' + imageName} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} />
          }
     }

     function GetImageData(auditType, image, imageArray) {
          const imageArrayHtmlData = [];
          if (auditType == 2) {
               if (image.indexOf(",") > 0) {
                    imageArray = image.split(",");
                    for (let index = 0; index < imageArray.length; index = index + 2) {


                         imageArrayHtmlData.push(<div className="row">
                              <div className="col-6">{GetImageTag(imageArray[index])}</div>
                              <div className="col-6">{GetImageTag(imageArray[index + 1])}</div>
                         </div>)



                    };

               }
               else {
                    imageArrayHtmlData.push(GetImageTag(image));
                    //  imageArrayHtmlData.push(<img src={BASE_URL + 'upload/question/' + image} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} />)
               }


          }
          else {
               // imageArray.map((rowData, index) => (
               for (let index = 0; index < imageArray.length; index = index + 2) {
                    //console.log(imageArray[index]?.image_data);
                    imageArrayHtmlData.push(<div className="row">
                         <div className="col-6">
                              {

                              GetImageTag(imageArray[index]?.image_data)}


                         </div>
                         <div className="col-6">
                              {GetImageTag(imageArray[index+1]?.image_data)}


                         </div>
                    </div>
                    )
               }

               // ));
          }
          return imageArrayHtmlData;
     }
     function handlePrint() {
          var printContents = document.getElementById('reportContent')?.innerHTML;
          var originalContents = document.body.innerHTML;

          document.body.innerHTML = printContents;

          window.print();

          document.body.innerHTML = originalContents;
     }

     const handleRejectAudit = async (audit_id) => {
          const params = {
               audit_id: auditRecord?.audit_id,
               rejectResion: rejectComment
          };
          var response = await apiCall(
               "POST",
               apiEndPoints.SETAUDITREJECT,
               params
          );

          console.log(response);
          if (response.status === 200) {

               setShowRejectBox(false)
               //showRejectBox = false;
               // setlist(response);
               // console.log('response.data.header: ', response.data);
          }
     }
     function SetRejectBox() {

          console.log("state", showRejectBox);
          if (showRejectBox == true) {

               return (<div><div className="row"><div className="col">
                    <textarea style={{ width: '90%', height: '200px' }} value={rejectComment} onChange={e => setRejectComment(e.target.value)}></textarea>
               </div>
               </div>
                    <div className="row"><div className="col">
                         {rejectComment}
                         <button className="btn btn-primary" type="View" onClick={handleRejectAudit} >Reject TPP Review </button>
                    </div></div>
               </div >);
          }
          else {
               return (<span>Reject Comment:<br></br> {rejectComment}</span>);
          }

     }

     return (
          <div>
               <Breadcrumb title="Report" parent="Report" />
               <div className="container-fluid">
                    <div id='reportContent'>
                         <h4>Branch TPP Review Report</h4>
                         <div className="row">
                              <div className="col"><span style={{ backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(6, 6, 6)', fontSize: '0.8rem' }}>TPP Review completion date: {auditRecord?.audit_date} TPP Review Completed By {auditRecord?.name}</span></div>
                         </div>
                         <div className="row">
                              <div className="col">Branch Name: {auditRecord?.branch_name}</div>

                         </div>
                         <div className="row">
                              <div className="col">Insurar Name: {auditRecord?.insurance_com}</div>
                         </div>
                         <div className="row" style={{ marginTop: '10px' }}>
                              <div className="col">
                                   <div className="row" style={{ border: '1px solid #cccccc', padding: '5px' }}>
                                        {
                                             props.list
                                                  ? auditRecord.questionArray.map((rowData, index) => (

                                                       <div className="col-6" style={{ padding: '5px', border: '1px solid #cccccc' }}>
                                                            <div className="row">
                                                                 <div className="col">
                                                                      <strong>{rowData?.audit_question} </strong>{rowData?.question_type == 1 ? rowData?.yes_no : rowData?.quality}
                                                                 </div>
                                                            </div>
                                                            <div className="row">
                                                                 <div className="col">
                                                                      {GetImageData(auditRecord?.audit_type, rowData.image_capture, auditRecord.images)}
                                                                 </div>

                                                            </div>
                                                            <div className="row">
                                                                 <div className="col">
                                                                      <strong>Remark</strong>:<br></br> {rowData?.remark}
                                                                 </div>
                                                            </div>


                                                       </div>

                                                  )) : null}
                                   </div>
                              </div>
                         </div>
                    </div>
                    {auditRecord?.video_url ?

                         <div className="row">
                              <div className="col" style={{ padding: '5px', border: '1px solid #cccccc',display:'none' }}><h4>Video:</h4>
                                   <video width="600" height="300" controls style={{ border: '1px solid #cccccc' }}>
                                        <source src={BASE_URL + "upload/video/" + auditRecord?.video_url} type="video/mp4" />
                                   </video>
                              </div>
                              <div className="col" style={{ padding: '5px', border: '1px solid #cccccc' }}><h4>Reject TPP Review </h4>
                                   {showRejectBox == true && auditRecord.todayactive_audit == 0 ?
                                        <div><div className="row"><div className="col">
                                             <textarea style={{ width: '90%', height: '200px' }} value={rejectComment} onChange={e => setRejectComment(e.target.value)}></textarea>
                                        </div>
                                        </div>
                                             <div className="row"><div className="col">
                                                  <button className="btn btn-primary" type="View" onClick={handleRejectAudit} >Reject TPP Review </button>
                                             </div></div>
                                        </div > : <span>Reject Comment:<br></br>  {rejectComment} {auditRecord.admin_cancel_reason}</span>
                                   }
                              </div>
                         </div> : <div className="row"><div className="col" style={{ padding: '5px', border: '1px solid #cccccc' }}><h4>Reject TPP Review</h4>
                              {showRejectBox == true && auditRecord.todayactive_audit == 0 ?
                                   <div><div className="row"><div className="col">
                                        <textarea style={{ width: '90%', height: '200px' }} value={rejectComment} onChange={e => setRejectComment(e.target.value)}></textarea>
                                   </div>
                                   </div>
                                        <div className="row"><div className="col">
                                             {rejectComment}
                                             <button className="btn btn-primary" type="View" onClick={handleRejectAudit} >Reject TPP Review </button>
                                        </div></div>
                                   </div > : <span>Reject Comment:<br></br>  {rejectComment} {auditRecord.admin_cancel_reason}</span>
                              }
                         </div></div>}

                    <div className="row">
                         <div className="col">
                              <a className="btn btn-primary"
                                   href={BASE_URL + "upload/pdf/" + auditRecord?.report}
                                   target="_blank"
                              >
                                   Download
                              </a>

                         </div>
                         <div className="col">
                              <button className="btn btn-primary" type="View" onClick={handlePrint} >Print </button>
                         </div>

                    </div>
               </div>
          </div>
     );
}
