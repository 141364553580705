import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React, { useState, useRef, useEffect } from "react";
export default function ExcelExport(props) {
     const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
     const fileExtension = ".xlsx";


     const SetData = (csvData) => {
          let jsonList = [];

          csvData.forEach(element => {
               let jsonData = {};
               jsonData['Review Id'] = element.audit_id;
               jsonData['Review Type'] = element.audit_type == 1 ? 'Online' : "Physical";
               jsonData['Branch Name'] = element.branch_name;
               jsonData['Name'] = element.auditorName;
               jsonData['Designation'] = element.role_name;
               jsonData["Review Date"] = element.audit_date;
               jsonData["Review Time"] = element.audit_time.toString().replace("-", ":");
               jsonData['Branch Code'] = element.branch_code;
               jsonData['Br Staus'] = element.branch_type == 1 ? 'New' : 'Existing';
               jsonData['Br Cat'] = element.branch_category;
               jsonData['Insurer Name'] = element.insurance_com;
               jsonData['Branch Manager'] = element.branch_manager;
               jsonData["City"] = element.city;
               jsonData["RBI Cat"] = element.rbi_class;
               jsonData["Geographical State"] = element.state;
               jsonData["RPM"] = element.rpm;
               jsonData["Sr RPMs"] = element.srpm;
               jsonData["Regional Head - TPP"] = element.rhead;
               jsonData["Region"] = element.region;
               jsonData["BBH Region"] = element.bbh_region;
               jsonData["Cluster"] = element.cluster;
               jsonData["Cluster Head"] = element.cluster_head;
               jsonData["Circle"] = element.circle;
               jsonData["Circle Head"] = element.circle_head;
               jsonData["Zone"] = element.zone;
               jsonData["Zonal Head"] = element.zonal_head;
               jsonData["Regional Head"] = element.regional_head;
               jsonData["BBH"] = element.bbh_head;
               jsonData["GH Name"] = element.group_head;

               AddQuestionHeader(jsonData, element.questionArray);

               jsonData["status"] = element.audit_status == 0 ? 'Pending' :
                    element.audit_status == 1 ? 'Process' :
                         element.audit_status == 2 ? 'Reject' :
                              element.audit_status == 3 ? 'Complete' :
                                   element.audit_status == 4 ? 'Process' : 'Complete';
               jsonList.push(jsonData);
          });
          const ws = XLSX.utils.json_to_sheet(jsonList);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, props.fileName + fileExtension);
        //  console.log("Csv Header", props.csvHeader);

     }
     function GetQuestionData(data) {

          if (data.question_type == "1") {
               return (<div>{data.yes_no} <br></br>Remark:  {data.remark}</div>)
          }
          else if (data.question_type == "2") {
               return (<div>{data.quality} <br></br>Remark: {data.remark}</div>)
          }


     }
     function AddQuestionHeader(jsonData, questionArray) {


          if (questionArray.length > 0) {
               for (let i = 0; i < questionArray.length; i++) {
                    if (questionArray[i].question_type == "1") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].yes_no;

                    }
                    else if (questionArray[i].question_type == "2") {
                         jsonData[questionArray[i].audit_question] = questionArray[i].quality;

                    }
                    jsonData['Remark-' + questionArray[i].audit_question] = questionArray[i].remark;


               }

          }
     }



     const exportToCSV = (csvData, fileName) => {
          SetData(csvData);
         // console.log("Export Data", csvData, fileName);



     }
     return (
          <div>
               <button  className="btn btn-primary" onClick={(e) => exportToCSV(props.csvData, props.fileName)}>Export</button>
          </div>);

}

