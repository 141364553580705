import React, { useState } from "react";
import "./style.css";
import moment from "moment";
import { Table } from "react-bootstrap";

import { BASE_URL } from '../../../utils/constants.js';
export default function ReportDataPdf(props) {

     const { list } = props;
     const [toggleFrom, settoggleFrom] = useState(false);
     const [toggleto, settoggleto] = useState(false);

     console.log("props-1", list);
     let auditRecord = {};
     // if (list) {
     //      auditRecord = list;
     // }
     // else {

     // }
     const gridCols = [[], []];

     function GetImageData(auditType, image, imageArray) {
          const imageArrayHtmlData = [];
          if (auditType == 2) {
               if (image.indexOf(",") > 0) {
                    imageArray = image.split(",");
                    for (let index = 0; index < imageArray.length; index = index + 2) {

                         imageArrayHtmlData.push(<div className="row">
                              <div className="col-6"><img src={BASE_URL + 'upload/question/' + imageArray[index]} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} /></div>
                              <div className="col-6"><img src={BASE_URL + 'upload/question/' + imageArray[index + 1]} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} /></div>
                         </div>)



                    };

               }
               else {
                    if (image != '') {
                         imageArrayHtmlData.push(<img src={BASE_URL + 'upload/question/' + image} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} />)
                    }
               }


          }
          else {
               imageArrayHtmlData.map((rowData, index) => (
                    imageArray.push(<div className="row">
                         <div className="col">
                              <img src={BASE_URL + 'upload/question/' + rowData['image_data']} style={{ width: '80%', border: '5px solid rgb(244 189 122)' }} />
                         </div>
                    </div>
                    )
               ));
          }
          return imageArrayHtmlData;
     }

     return (
          <div style={{ display: 'none' }} id='reportDataInPdf'>
               {props.list ? props.list.map((auditRecord, index) => (
                    <div style={{ borderBottom: '3px solid #333', paddingBottom: '5px' }}>


                         <div id='reportContent'>
                              <h4>Branch Review Report</h4>
                              <div className="row">
                                   <div className="col"><span style={{ backgroundColor: 'rgb(255, 255, 255)', color: 'rgb(6, 6, 6)', fontSize: '0.8rem' }}> Review completion date: {auditRecord?.audit_date}  Review Completed By {auditRecord?.name}</span></div>
                              </div>
                              <div className="row">
                                   <div className="col">Branch Name: {auditRecord?.branch_name}</div>

                              </div>
                              <div className="row">
                                   <div className="col">Insurar Name: {auditRecord?.insurance_com}</div>
                              </div>
                              <div className="row" style={{ marginTop: '10px' }}>
                                   <div className="col">
                                        <div className="row" style={{ border: '1px solid #cccccc', padding: '5px' }}>

                                             {

                                                  props.list
                                                       ? auditRecord.questionArray.map((rowData, index) => (

                                                            <div className="col-6" style={{ padding: '5px', border: '1px solid #cccccc' }}>
                                                                 <div className="row">
                                                                      <div className="col">
                                                                           <strong>{rowData?.audit_question} </strong>{rowData?.question_type == 1 ? rowData?.yes_no : rowData?.quality}
                                                                      </div>
                                                                 </div>
                                                                 <div className="row">
                                                                      <div className="col">
                                                                           {GetImageData(auditRecord?.audit_type, rowData.image_capture, auditRecord.images)}
                                                                      </div>

                                                                 </div>
                                                                 <div className="row">
                                                                      <div className="col">
                                                                           <strong>Remark</strong>:<br></br> {rowData?.remark}
                                                                      </div>
                                                                 </div>


                                                            </div>

                                                       )) : null}
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <hr ></hr>
                    </div>

               )) : null
               }
          </div>
     );
}
